import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";

import { App } from "./app/App";
import { reportWebVitals } from "./reportWebVitals";

import { worker } from "~/api/mock/worker";
import { envConfig } from "~/app/configs";

if (envConfig.enableApiMock) {
  void worker.start();
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
