import { Stack, StackProps } from "@mui/material";
import { useEffect, useState } from "react";

export interface ContainerProps {
  /**
   * このコンポーネントの高さを画面の高さに固定する
   */
  fixedHeight?: boolean;
  sx?: StackProps["sx"];
  children?: React.ReactNode;
}

/**
 * アプリケーション全体を覆うためのコンポーネント
 * このコンポーネントは子要素に関わらず、少なくとも画面の高さと同じだけの高さを持ち、画面全体を覆う。
 */
export const Container: React.FC<ContainerProps> = (props) => {
  const [height, setHeight] = useState(window.innerHeight);

  // NOTE: css の vh は ios safari で期待通りに動かないため JS で対応
  useEffect(() => {
    const listener = () => setHeight(window.innerHeight);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, []);

  const containerHeight = props.fixedHeight
    ? {
        height: height,
      }
    : {
        minHeight: height,
      };

  return (
    <Stack
      sx={{
        ...props.sx,
        paddingTop: `env(safe-area-inset-top)`,
        paddingBottom: `env(safe-area-inset-bottom)`,
      }}
      width="100%"
      {...containerHeight}
      // 左右中央寄せのためのflex
      alignItems="center"
    >
      {props.children}
    </Stack>
  );
};
