/**
 * NOTE: messagesはスナックバーやバリデーションエラーなど状況によって変更される文言とする。Templateで使用する画面文言はここに定義せず、べた書き実装で良い。
 */
export const messages = {
  errorMessages: {
    common: {
      unknown:
        "予期しないエラーが発生しました。後でもう一度やり直してください。",
      network:
        "インターネット接続が不安定です。後でもう一度やり直してください。",
      sessionTimeout: "セッションが切れました。再度ログインしてください。",
      rateLimit: "操作回数が上限に達しました。1時間後に再度お試しください。",
    },
    login: {
      authenticationFailed:
        "メールアドレスまたは、パスワードが正しくありません。",
    },
  },
  validationMessages: {
    required: "必須項目です。",
    login: {
      email: "有効なメールアドレスを入力してください。",
      password: "パスワードが正しくありません。",
    },

    max: (num: number): string =>
      `${num.toString()}文字以下の文字列で入力してください。`,
    min: (num: number): string =>
      `${num.toString()}文字以上の文字列で入力してください。`,
  },
};

export type MessagesType = typeof messages;
