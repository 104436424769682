import { Auth } from "aws-amplify";
import { AxiosInterceptorManager, InternalAxiosRequestConfig } from "axios";

export const createIdTokenInterceptor = (): Parameters<
  AxiosInterceptorManager<InternalAxiosRequestConfig>["use"]
> => [
  async (config) => {
    let token;
    try {
      token = (await Auth.currentSession()).getIdToken().getJwtToken();
    } catch (err) {
      return config;
    }
    // 既存のconfigにsetメソッドを使って既存のheaderにAuthorizationヘッダーを追加している
    // 参考: https://github.com/axios/axios/issues/5034
    config.headers.set("Authorization", token);
    return config;
  },
  async (err) => {
    throw err;
  },
];
