import { useEffect } from "react";
import { useLocation } from "react-router";

/**
 * パスが変化したときスクロール状態をリセットするコンポーネント
 */
export const useScrollResetOnNavigation = (): void => {
  const { pathname } = useLocation();

  useEffect(() => {
    // ページのスクロール状態を最左かつ最上にする
    window.scrollTo(0, 0);
  }, [pathname]);
};
