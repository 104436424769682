import debounce from "debounce";
import { RefObject, useEffect, useRef } from "react";
import ResizeObserverPolyfill from "resize-observer-polyfill";

const ResizeObserver = window.ResizeObserver ?? ResizeObserverPolyfill;

export const useAspectRatio = (ratio: number): RefObject<HTMLElement> => {
  const ref = useRef<HTMLElement>(null);
  useEffect(() => {
    const elem = ref.current;
    if (elem === null) {
      return;
    }
    const observer = new ResizeObserver(
      debounce((entries) => {
        elem.style.height = `${entries[0].contentRect.width / ratio}px`;
      }, 100),
    );
    observer.observe(elem);
    return () => {
      observer.unobserve(elem);
    };
  }, [ratio]);
  return ref;
};
