import { rest } from "msw";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCustomMSW = () => [
  rest.get("*/me", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        userId: "d6e1212e-5fa0-4303-bc93-90043d882329",
      }),
    );
  }),
];
