import { TextField, TextFieldProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { ChangeEvent, useCallback, useState } from "react";

import { EyeButton } from "./EyeButton";

// https://developer.mozilla.org/ja/docs/Web/HTML/Attributes/autocomplete#Browser_compatibility
export type AutoCompleteType =
  | "username"
  | "email"
  | "current-password"
  | "new-password"
  | "one-time-code";

export type InputModeOptions = "text" | "numeric";

export type AdditionalProps = {
  maxLength?: number;
  secure?: boolean;
  autoComplete?: AutoCompleteType;
  inputMode?: InputModeOptions;
  value?: string;
  hasError?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (e: any) => void;
};

export type TextInputProps = Omit<
  TextFieldProps,
  keyof AdditionalProps | "type"
> &
  AdditionalProps;

const useStyles = makeStyles(() => ({
  input: {
    // IE独自のパスワード表示ボタンを非表示にする
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "&::-ms-reveal": {
      display: "none",
    },
    // IE独自のクリアボタンを非表示にする
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "&::-ms-clear": {
      display: "none",
    },
  },
}));

export const TextInput: React.FC<TextInputProps> = (props) => {
  const {
    maxLength,
    secure,
    autoComplete,
    inputMode,
    value,
    hasError,
    onChange,
    onBlur,
    ...restProps
  } = props;

  const classes = useStyles();

  const [visible, setVisible] = useState(false);
  const onClickEye = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const onChangeAdaptor = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <TextField
      {...restProps}
      type={visible || !secure ? "text" : "password"}
      inputProps={{
        ...{
          maxLength,
          className: classes.input,
          inputMode,
        },
        ...props.inputProps,
      }}
      InputProps={{
        ...{
          endAdornment: secure && (
            <EyeButton onClick={onClickEye} visible={visible} />
          ),
        },
        ...props.InputProps,
      }}
      autoComplete={autoComplete}
      value={value}
      error={hasError}
      onChange={onChangeAdaptor}
      onBlur={onBlur}
    />
  );
};
