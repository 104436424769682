import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { IconButton, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  SnackbarProvider as NotistackSnackbarProvider,
  OptionsObject,
  ProviderContext,
  SnackbarKey,
  useSnackbar,
} from "notistack";
import React, { useMemo } from "react";

const useStyles = makeStyles<Theme, { isRTL: boolean }>((theme) => ({
  root: (param) => {
    return {
      // snackbarの閉じるボタンへのスタイル
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "& > div > div:last-child": {
        // デフォルトの値をRTLに切替
        paddingLeft: param.isRTL ? 0 : theme.spacing(2),
        paddingRight: param.isRTL ? theme.spacing(2) : 0,
        marginLeft: param.isRTL ? theme.spacing(-1) : "auto",
        marginRight: param.isRTL ? "auto" : theme.spacing(-1),
        // 絶対位置指定で右側(左側)に表示
        position: "absolute",
        right: param.isRTL ? undefined : theme.spacing(3),
        left: param.isRTL ? theme.spacing(3) : undefined,
        // 上下中央揃え
        top: 0,
        bottom: 0,
      },
      // snackbarのテキストへのスタイル
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "& > div > div:first-child": {
        // ボタンに重ならないように十分なパディングを指定
        paddingRight: param.isRTL ? undefined : theme.spacing(5),
        paddingLeft: param.isRTL ? theme.spacing(5) : undefined,
      },
    };
  },
  success: {
    // importantが指定されているnotistackのスタイルを上書き
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.primary.contrastText,
  },
  error: {
    // importantが指定されているnotistackのスタイルを上書き
    backgroundColor: `${theme.palette.error.main} !important`,
    color: theme.palette.error.contrastText,
  },
  container: {
    paddingBottom: theme.spacing(4),
  },
}));

export type SnackbarProviderProps = {
  isRTL?: boolean;
  children?: React.ReactNode;
};

export const SnackbarProvider: React.FC<SnackbarProviderProps> = (props) => {
  const isRTL = props.isRTL ?? false;

  const classes = useStyles({ isRTL });

  // Reactのref機能を使ってSnackbarProviderのインスタンスにアクセスできるようにする
  // notistackRef.currentがSnackbarProviderのインスタンスを表す
  // SnackbarProviderにはcloseSnackbarなどのsnackbar操作用関数がある
  // 参考: https://iamhosseindhv.com/notistack/demos#action-for-all-snackbars
  const notistackRef = React.useRef<NotistackSnackbarProvider>(null);

  return (
    <NotistackSnackbarProvider
      hideIconVariant
      ref={notistackRef}
      classes={{
        root: classes.root,
        variantSuccess: classes.success,
        variantError: classes.error,
        containerRoot: classes.container,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      autoHideDuration={5000} // milliseconds
      // 閉じるボタン
      action={(key) => (
        <IconButton
          size="small"
          color="inherit"
          onClick={() => {
            if (notistackRef.current) {
              notistackRef.current.closeSnackbar(key);
            }
          }}
        >
          <Icon path={mdiClose} size="24px" color="white" />
        </IconButton>
      )}
    >
      {props.children}
    </NotistackSnackbarProvider>
  );
};

export interface CustomSnackbar {
  info(message: string, option?: OptionsObject): SnackbarKey;
  success(message: string, option?: OptionsObject): SnackbarKey;
  warning(message: string, option?: OptionsObject): SnackbarKey;
  error(message: string, option?: OptionsObject): SnackbarKey;
  close(key: SnackbarKey): void;
  closeAll(): void;
}

const enqueueSnackbarHelper =
  (
    snackbar: ProviderContext,
    variant: "default" | "info" | "success" | "warning" | "error",
  ) =>
  (message: string, options?: OptionsObject) => {
    return snackbar.enqueueSnackbar(
      message,
      Object.assign<OptionsObject, OptionsObject | undefined>(
        { variant: variant, preventDuplicate: true },
        options,
      ),
    );
  };

export const useCustomSnackbar = (): CustomSnackbar => {
  const snackbar = useSnackbar();
  const customSnackbar = useMemo<CustomSnackbar>(() => {
    return {
      info: enqueueSnackbarHelper(snackbar, "info"),
      success: enqueueSnackbarHelper(snackbar, "success"),
      warning: enqueueSnackbarHelper(snackbar, "warning"),
      error: enqueueSnackbarHelper(snackbar, "error"),
      close(key) {
        snackbar.closeSnackbar(key);
      },
      closeAll() {
        snackbar.closeSnackbar();
      },
    };
  }, [snackbar]);
  return customSnackbar;
};
