import { CssBaseline, ThemeProvider } from "@mui/material";
import { Amplify } from "aws-amplify";
import React from "react";
import { BrowserRouter } from "react-router-dom";

import { AppAuthStateProvider } from "./AppAuthStateProvider";

import { envConfig, theme } from "~/app/configs";
import { Navigation } from "~/app/navigation/Navigation";
import { ErrorBoundary } from "~/lib/components/providers/ErrorBoundary";
import { ReactQueryProvider } from "~/lib/components/providers/ReactQueryProvider";
import { SnackbarProvider } from "~/lib/components/providers/SnackbarProvider";

export const App: React.FC = () => {
  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: envConfig.cognito.region,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: envConfig.cognito.userPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: envConfig.cognito.clientId,
    },
  });

  return (
    <ErrorBoundary>
      <ReactQueryProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider>
            <BrowserRouter>
              <AppAuthStateProvider>
                <Navigation />
              </AppAuthStateProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </ReactQueryProvider>
    </ErrorBoundary>
  );
};
