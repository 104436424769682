import { Box, CircularProgress, Typography } from "@mui/material";
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { theme } from "~/app/configs";
import { Container } from "~/lib/components/atoms";

export const MainLayout: React.FC = () => {
  //TODO: レイアウトが決まり次第修正する
  return (
    <Container>
      <Header />
      <Box>
        <Suspense fallback={<CircularProgress size={24} />}>
          <Outlet />
        </Suspense>
      </Box>
      <Footer />
    </Container>
  );
};

//NOTE: 必要に応じてヘッダーやフッター、ドロワーの部品を用意する
const Header: React.FC = () => {
  return (
    <>
      <Typography
        sx={{
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.background.default,
        }}
      >
        ------------------- ヘッダー -------------------
      </Typography>
    </>
  );
};

const Footer: React.FC = () => {
  return (
    <>
      <Typography
        sx={{
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        ------------------- フッター -------------------
      </Typography>
    </>
  );
};
