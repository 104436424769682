import { loadConfig } from "~/lib/configs/loadConfig";

export const envConfig = {
  envName: loadConfig("REACT_APP_ENV_NAME").toString(),

  api: {
    baseUrl: loadConfig("REACT_APP_API_URL").toString(),
    timeout: loadConfig("REACT_APP_API_TIMEOUT").toNumber(),
  },
  cognito: {
    region: loadConfig("REACT_APP_AWS_REGION").toString(),
    userPoolId: loadConfig("REACT_APP_AWS_COGNITO_USER_POOL_ID").toString(),
    clientId: loadConfig("REACT_APP_AWS_COGNITO_CLIENT_ID").toString(),
  },
  enableApiMock: loadConfig("REACT_APP_ENABLE_API_MOCK").toBoolean(),
  apiMockDelayTime: loadConfig("REACT_APP_API_MOCK_DELAY_TIME").toNumber(),
};
