import axios, { AxiosInstance } from "axios";

import { createApiLoggingInterceptor } from "./api-logging-interceptor";
import { createIdTokenInterceptor } from "./id-token-interceptor";

export type ApiClientOptions = {
  baseURL: string;
};

export const createApiClient = (options: ApiClientOptions): AxiosInstance => {
  const instance = axios.create({
    baseURL: options.baseURL,
    timeout: 30 * 1000,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
  });

  instance.interceptors.request.use(...createIdTokenInterceptor());
  instance.interceptors.response.use(...createApiLoggingInterceptor());

  return instance;
};
