/* eslint-disable @typescript-eslint/naming-convention */
import {
  CircularProgress,
  Button as MButton,
  StackProps,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles<Theme, ButtonProps>((theme) => {
  return {
    muiButtonRoot: {
      // フォントの大きさや改行に対応できるよう、Buttonの高さは直接指定せずpaddingで調整
      paddingTop: "1.3rem",
      paddingBottom: "1.3rem",
    },

    // Containedボタンの場合のスタイル
    muiButtonContained: (props) => ({
      // Material-uiのButtonコンポーネントのcolor属性を使用すると、
      // "primary" | "secondary" | "inherit" | "default" しか指定できないため
      // スタイルとしてボタンの文字色・背景色を指定する
      color: theme.palette[props.color].contrastText,
      backgroundColor: theme.palette[props.color].main,

      "&:hover": {
        backgroundColor: theme.palette[props.color].dark,
      },
      // ホバーのサポートが不完全なデバイスではホバーのスタイルを打ち消す
      "@media (hover: none)": {
        // .MuiButton-rootなしでは優先度が低く、スタイルが適用されない
        "&.MuiButton-root:hover": {
          backgroundColor: `${theme.palette[props.color].main}`,
        },
      },
    }),

    // Outlinedボタンの場合のスタイル
    muiButtonOutlined: (props) => ({
      borderWidth: "0.2rem",
      // Material-uiのButtonコンポーネントのcolor属性を使用すると、
      // "primary" | "secondary" | "inherit" | "default" しか指定できないため
      // スタイルとしてボタンの文字色・背景色を指定する
      color: theme.palette[props.color].main,
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette[props.color].main,

      "&:hover": {
        // マウスオーバー時の枠線の太さをマウスオーバー前に合わせる
        borderWidth: "0.2rem",
        backgroundColor: theme.palette[props.color].light,
      },
      // ホバーのサポートが不完全なデバイスではホバーのスタイルを打ち消す
      "@media (hover: none)": {
        // .MuiButton-rootなしでは優先度が低く、スタイルが適用されない
        "&.MuiButton-root:hover": {
          backgroundColor: `${theme.palette.common.white}`,
        },
      },
    }),

    muiButtonDisabled: (props) => ({
      // material-ui由来のスタイルを上書きするためimportantにする
      color: `${theme.palette[props.color].contrastText} !important`,
      backgroundColor: `${theme.palette[props.color].dark} !important`,
    }),

    spinner: (props) => ({
      color: theme.palette[props.color].contrastText,
      marginRight: theme.spacing(1),
    }),
  };
});

export interface ButtonProps {
  label: string;
  variant: "text" | "contained" | "outlined";
  color: "primary" | "secondary";
  enableSubmit?: boolean;
  onClick?: () => void;
  isProcessing?: boolean;
  startIcon?: React.ReactElement;
  sx?: StackProps["sx"];
}

export const Button: React.FC<ButtonProps> = (props) => {
  const classes = useStyles(props);

  return (
    <MButton
      variant={props.variant}
      type={props.enableSubmit ? "submit" : "button"}
      className={classes.muiButtonRoot}
      classes={{
        contained: classes.muiButtonContained,
        outlined: classes.muiButtonOutlined,
        disabled: classes.muiButtonDisabled,
      }}
      disableElevation
      fullWidth
      disabled={props.isProcessing}
      onClick={props.onClick}
      startIcon={props.startIcon}
      sx={props.sx}
    >
      {props.isProcessing && (
        <CircularProgress size={16} className={classes.spinner} />
      )}
      <Typography variant="button" noWrap>
        {props.label}
      </Typography>
    </MButton>
  );
};
