import React, { ReactNode } from "react";

interface ErrorBoundaryProps {
  children?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  message?: string;
}

/**
 * React のレンダリングに想定外のエラーが起きた時、フォールバックUIを表示する
 */
export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, message: undefined };
  }

  static getDerivedStateFromError(error: unknown): ErrorBoundaryState {
    console.error(error);

    let message = undefined;
    try {
      if (error instanceof Object) {
        error = error.toString();
      }
      message = JSON.stringify(error);
    } catch (e) {
      // あまり想定されないが、エラーが循環参照を持つオブジェクトのときなどはエラーになる
      // このときエラーの文字列化を諦める
      // 最低限、コンソールにエラーは表示されるはず
      console.error(e);
    }
    return { hasError: true, message };
  }

  render(): ReactNode {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <>
        <h1>不明なエラーが発生しました</h1>
        <div>
          <h2>エラー詳細</h2>
          <code>
            {this.state.message ??
              "エラーの表示に失敗しました。コンソールを確認してください。"}
          </code>
        </div>
      </>
    );
  }
}
