export type ApiCommonErrorCode =
  | "unknown"
  | "network"
  | "timeout"
  | "not-authenticated"
  | "rate-limit";

export class ApiError<ApiExtraErrorCode extends string> extends Error {
  constructor(
    readonly code: ApiCommonErrorCode | ApiExtraErrorCode,
    message: string,
    cause: unknown,
  ) {
    super(message, { cause });

    Object.defineProperty(this, "name", {
      configurable: true,
      enumerable: false,
      value: this.constructor.name,
      writable: true,
    });

    if (cause instanceof Error && cause.stack !== undefined) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      this.stack = `${this.stack}\nCaused By: ${cause.stack}`;
    }
  }
}
