import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import { AppAuthRoute } from "~/app/AppAuthStateProvider";
import { AuthLayout } from "~/app/navigation/AuthLayout";
import { MainLayout } from "~/app/navigation/MainLayout";
import { paths } from "~/app/navigation/paths";
import { useScrollResetOnNavigation } from "~/lib/hooks";

const LoginPage = lazy(() => import("~/app/features/login/LoginPage"));
const SamplePage = lazy(() => import("~/app/features/sample/SamplePage"));

//NOTE: 画面とパスを紐づける
export const Navigation: React.FC = () => {
  useScrollResetOnNavigation();
  return (
    <Routes>
      {/* TODO: サンプル画面は削除する */}
      <Route element={<AuthLayout />}>
        <Route
          path={paths.sample}
          element={
            <AppAuthRoute>
              <SamplePage />
            </AppAuthRoute>
          }
        />
      </Route>
      <Route element={<MainLayout />}>
        <Route path={paths.login} element={<LoginPage />} />
        <Route path="*" element={<LoginPage />} />
      </Route>
    </Routes>
  );
};
