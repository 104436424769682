import { Auth } from "aws-amplify";
import { Navigate } from "react-router-dom";

import { useGetMe } from "~/api/client/user";
import { AuthState } from "~/app/models/authState";
import { FullScreenSpinner } from "~/lib/components/molecules";
import {
  AuthStateProvider,
  useAuthState,
} from "~/lib/components/providers/AuthStateProvider";

export type AuthStateProviderProps = {
  children?: React.ReactNode;
};

export const AppAuthStateProvider: React.FC<AuthStateProviderProps> = (
  props,
) => {
  // アプリを初期表示したときに、ログイン済みかどうかチェックするため、APIを呼び出す
  // ユーザ情報が取得出来たら、ログイン済みである
  // 取得できたユーザ情報を、ログイン状態として設定する

  const { refetch } = useGetMe({
    query: {
      enabled: false,
    },
  });

  const initAuthState = async (): Promise<AuthState | undefined> => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      await Auth.currentAuthenticatedUser();

      const res = await refetch();
      // NOTE : refetch した結果がエラーだった場合はエラーをスローする
      if (res.isError) {
        throw res.error;
      }

      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...res.data!,
      };
    } catch (err) {
      console.log(err);
      return undefined;
    }
  };

  return (
    <AuthStateProvider
      initAuthState={initAuthState}
      LoadingComponent={FullScreenSpinner}
    >
      {props.children}
    </AuthStateProvider>
  );
};

/**
 * カスタマイズされたReactRouterのRouteコンポーネント
 * 認証状態が無効の場合はログイン画面に強制遷移させる
 */

export const AppAuthRoute: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [authState] = useAuthState<AuthState>();

  //NOTE: 認証しない場合はコメントアウトして動作確認する
  //NOTE: ユーザ属性登録が済んでいない場合は、登録画面に遷移させるべき
  if (authState === undefined) {
    return <Navigate to="/login" />;
  }

  return children;
};
