import { CircularProgress, Dialog, Stack } from "@mui/material";
import React from "react";

export const FullScreenSpinner: React.FC = () => {
  return (
    <Dialog open={true} fullScreen transitionDuration={0}>
      <Stack
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={64} />
      </Stack>
    </Dialog>
  );
};
