/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * ボイラープレートAPI
 * ボイラープレートAPI定義
 * OpenAPI spec version: 1.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { User, UnauthorizedResponse } from "../model";
import { rest } from "msw";
import { faker } from "@faker-js/faker";
import { customMutator } from "../custom-mutator";
import type { ErrorType } from "../custom-mutator";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * ログインユーザを取得するAPIです。
 * @summary ログインユーザ取得API
 */
export const getMe = (signal?: AbortSignal) => {
  return customMutator<User>({ url: `/me`, method: "get", signal });
};

export const getGetMeQueryKey = () => [`/me`];

export type GetMeQueryResult = NonNullable<Awaited<ReturnType<typeof getMe>>>;
export type GetMeQueryError = ErrorType<UnauthorizedResponse>;

export const useGetMe = <
  TData = Awaited<ReturnType<typeof getMe>>,
  TError = ErrorType<UnauthorizedResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMe>>> = ({
    signal,
  }) => getMe(signal);

  const query = useQuery<Awaited<ReturnType<typeof getMe>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getGetMeMock = () => ({ userId: faker.random.word() });

export const getUserMSW = () => [
  rest.get("*/me", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetMeMock())
    );
  }),
];
