import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";

// グローバル設定
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

type ReactQueryProviderProps = {
  children: React.ReactNode;
};
export const ReactQueryProvider: React.FC<ReactQueryProviderProps> = (
  props
) => {
  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
    </QueryClientProvider>
  );
};
