import { Theme, createTheme } from "@mui/material/styles";
import { TypographyStyleOptions } from "@mui/material/styles/createTypography";

// makeStyles は @mui/styles にあり、 Theme は @mui/material にある
// @mui/styles は @mui/material の Theme のことを知らない
// makeStyles で Theme を扱えるように型を拡張する
// cf. https://mui.com/material-ui/guides/migration-v4/#types-property-quot-palette-quot-quot-spacing-quot-does-not-exist-on-type-defaulttheme
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const makeTypography = (
  fontWeight: "normal" | "bold",
  spFontSize: string,
  tabletFontSize: string,
  lineHeight: number,
): TypographyStyleOptions => {
  return {
    lineHeight,
    fontWeight,

    fontSize: spFontSize,
    "@media (min-width: 600px)": {
      fontSize: tabletFontSize,
    },
  };
};

//TODO: デザイン決まり次第各色を設定する。拡張する場合は別途型定義が必要
// cf. https://codesandbox.io/s/ywmg2y?file=/demo.tsx
export const theme = createTheme({
  spacing: (factor: number) => `${0.8 * factor}rem`,
  palette: {
    common: {
      black: "#000000",
      white: "#FFFFFF",
    },
    primary: {
      main: "#20A5DF",
      light: "#72C2E9",
      dark: "#0071A3",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#E05C7C",
      light: "#E98708",
      dark: "#BB2247",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#DF2020",
      light: "#E97272",
      dark: "#A30000",
    },
    text: {
      primary: "#2F2F2F",
      secondary: "#969696",
      disabled: "#B7B7B7",
    },
    background: {
      default: "#EBECEF",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          // 62.5% of 16px = 10px
          fontSize: "62.5%",
          // iOSで勝手に文字サイズを調節されることを防ぐ
          WebkitTextSizeAdjust: "100%",

          // Chromeで画像がぼやけることを防ぐ
          imageRendering: "-webkit-optimize-contrast",
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        // SSRが有効だとuseMediaQueryによって2回renderが起きてしまうのでこれを無効化
        noSsr: true,
      },
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: ["Noto Sans", "Noto Sans Arabic", "sans-serif"].join(","),
    h1: makeTypography("bold", "1.6rem", "1.8rem", 1.5),
    h2: makeTypography("bold", "1.4rem", "1.6rem", 1.5),
    h3: makeTypography("bold", "1.2rem", "1.4rem", 1.5),
    body1: makeTypography("normal", "1.4rem", "1.6rem", 1.5),
    body2: makeTypography("normal", "1.2rem", "1.4rem", 1.5),
    subtitle1: makeTypography("bold", "2.0rem", "2.2rem", 1.5),
    subtitle2: makeTypography("bold", "1.2rem", "1.4rem", 1.5),
    button: makeTypography("bold", "1.6rem", "1.8rem", 1.5),
    caption: makeTypography("normal", "1.0rem", "1.2rem", 1.5),
  },
});
